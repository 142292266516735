import { CATEGORIAS, CREATE_OFFER_API, LOCALE_API, NEGOCIATION_API, OFFER_DECLINE, OFFER_FINISH, OFFER_LIST_API, OFFER_REFUSE} from "../constants"

export const postOffer = async (value, id, valor, desconto) => {
    const url = CREATE_OFFER_API + value;

    try{
        const settings = { 
            method: 'POST',     
            mode: 'cors',
            headers: new Headers({       'Content-Type': 'application/json'     }),
            body: JSON.stringify({
                id: id,
                value: valor,
                desconto: desconto,
            })
        }
        const fetchResponse = await fetch(url, settings);
        const data = await fetchResponse.json()
        return data
    } catch(error) {
        return error;
    }
}

export const getBanca = async (id) => {
    const url = LOCALE_API + id;
    try{
        const settings = { 
            method: 'GET',     
            mode: 'cors',
            headers: new Headers({       'Content-Type': 'application/json'     }),
        }
        const fetchResponse = await fetch(url, settings);
        const data = await fetchResponse.json()
        return data
    } catch(error) {
        return undefined;
    }
}

export const getCategories = async () => {
    const url = CATEGORIAS;
    try{
        const settings = { 
            method: 'GET',     
            mode: 'cors',
            headers: new Headers({       'Content-Type': 'application/json'     }),
        }
        const fetchResponse = await fetch(url, settings);
        const data = await fetchResponse.json()
        return data
    } catch(error) {
        return undefined;
    }
}

export const getOfferList = async (id) => {
    const url = OFFER_LIST_API + id;
    try{
        const settings = { 
            method: 'GET',     
            mode: 'cors',
            headers: new Headers({       'Content-Type': 'application/json'     }),
        }
        const fetchResponse = await fetch(url, settings);
        const data = await fetchResponse.json()
        return data
    } catch(error) {
        return undefined;
    }
}

export const postOfferFinish = async (id, banca) => {
    const url = OFFER_FINISH + id;

    try{
        const settings = { 
            method: 'POST',     
            mode: 'cors',
            headers: new Headers({       'Content-Type': 'application/json'     }),
            body: JSON.stringify({
                id: banca,
            })
        }
        const fetchResponse = await fetch(url, settings);
        const data = await fetchResponse.json()
        return data
    } catch(error) {
        return error;
    }
}

export const postOfferDecline = async (id, banca) => {
    const url = OFFER_DECLINE + id;

    try{
        const settings = { 
            method: 'POST',     
            mode: 'cors',
            headers: new Headers({       'Content-Type': 'application/json'     }),
            body: JSON.stringify({
                id: banca,
            })
        }
        const fetchResponse = await fetch(url, settings);
        const data = await fetchResponse.json()
        return data
    } catch(error) {
        return error;
    }
}

export const postOfferRefuse = async (id, banca) => {
    const url = OFFER_REFUSE + id;

    try{
        const settings = { 
            method: 'POST',     
            mode: 'cors',
            headers: new Headers({       'Content-Type': 'application/json'     }),
            body: JSON.stringify({
                id: banca,
            })
        }
        const fetchResponse = await fetch(url, settings);
        const data = await fetchResponse.json()
        return data
    } catch(error) {
        return error;
    }
}