import { useEffect, useState } from 'react';
import * as service from '../services';
import * as serviceFeirante from '../../FeiranteContext/services';
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth'
import app from '../../../service/firebaseConnection';
import { Navigate } from 'react-router-dom';

export default function AppHooks({ collection, getDoc, doc, getDocs, query, where }){
    const functions = getFunctions(app);
    connectFunctionsEmulator(functions, "localhost", 5002);

    const auth = getAuth();

    useEffect(() => {
        const unSubscribe = onAuthStateChanged(auth, (userFire) => {
            localStorage.setItem("@detailUser", JSON.stringify(userFire));
        });
        return unSubscribe;
    }, [])


    useEffect(() => {
        setSigned(JSON.parse(localStorage.getItem("@signed")))
    })

    
    const [product, setProduct] = useState("");
    const [localeList, setLocaleList] = useState([]);
    const [offerList, setOfferList] = useState([]);
    const [offerState, setOfferState] = useState({});
    const [chatList, setChatList] = useState([]);
    const [signed, setSigned] = useState(false);

    const search = async (searchName) => {
        const aux = searchName;
        try{
            setProduct(aux);
            const response = await service.getSearchV2(aux);
            return response
        } catch(error){
            console.log(error)
        }
    }

    const offerfinish = async (id,banca) => {
        await serviceFeirante.postOfferFinish(id,banca);
    }

    const signIn = async (email,password) => {
        let uid = ""
        await signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            uid = userCredential.user.uid;
            const test = {
                uid: uid,
                email: email
            }

            localStorage.setItem("@detailUser", JSON.stringify(test));
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode,errorMessage);
        });

        return uid
    }

    const logout = async () => {
        await signOut(auth);
        setSigned(false);
        localStorage.setItem("@detailUser", "");
    }

    const AllResults = async (searchName) => {
        const aux = searchName;
        try{
            const response = await service.getLocaleList(aux);
            setLocaleList(response);
        } catch(error){
            console.log(error)
        }
    }

    const signOn = async (email,password) => {
        let uid = ""
        await createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          uid = userCredential.user.uid;
          const test = {
              uid: uid,
              email: email
          }

          localStorage.setItem("@detailUser", JSON.stringify(test));
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode,errorMessage);
        });

        return uid
    }

    const offer = async (value) => {
        try{
            const response = await service.getNegociation(value);
            setLocaleList(response.negociation.bancas)
            setProduct(response.negociation.produto)
            const test = response.offer.sort((a,b) => a.data - b.data)
            setOfferList(test)
        } catch(error){
            console.log(error)
        }
    }

    const offerUnity = async (id,banca) => {
        try{
            const response = await service.getOffer(id,banca);
            setOfferState(response);
        } catch(error){
            console.log(error)
        }
    }

    const chatSend = async (id,banca,message,enviado) => {
        try{
            const response = await service.postChat(id,banca,message,enviado);
            console.log(response)
        } catch(error){
            console.log(error)
        }
    }

    const cadChatSend = async (value,id) => {
        try{
            const response = await service.postChatCad(value,id);
            console.log(response)
        } catch(error){
            console.log(error)
        }
    }

    const chatGet = async (id,banca) => {
        try{
            const response = await service.getChat(id,banca);
            setChatList(response.reverse());
        } catch(error){
            console.log(error)
        }
    }

    return (
        {
            offerList,
            product,
            localeList,
            offerState,
            offer,
            search,
            setLocaleList,
            offerUnity,
            chatSend,
            chatGet,
            chatList,
            auth,
            signOn,
            signIn,
            cadChatSend,
            signed, 
            setSigned,
            AllResults,
            logout,
            setOfferList,
            offerfinish
        }
      );
}