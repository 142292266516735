const { LOCAL_API } = require("../../../constants/serviceConstants");

export const NEGOCIATION_API = LOCAL_API + "v1/negociation/";

export const OFFER_API = LOCAL_API + "v1/offer/"

export const CREATE_OFFER_API = LOCAL_API + "v1/offerCreate/";

export const LOCALE_API = LOCAL_API + "v1/banca/";

export const OFFER_LIST_API = LOCAL_API + "v1/getNegociations/";

export const OFFER_FINISH = LOCAL_API + "v1/offerFinish/";

export const OFFER_REFUSE = LOCAL_API + "v1/offerRefuse/";

export const OFFER_DECLINE = LOCAL_API + "v1/offerDecline/";

export const CATEGORIAS = LOCAL_API + "v1/categoria";

export const ML_API = "https://api.mercadolibre.com/sites/MLB/search?q=";
