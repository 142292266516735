import React, {Suspense, useContext, useEffect} from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AppContext } from '../contexts/AppContext';
import { AuthRoute } from './Auth.routes';
import Private from './Private.routes';

const Home = React.lazy(() => import('../screens/Home'))
const ResultList = React.lazy(() => import('../screens/ResultList'))
const Product = React.lazy(() => import('../screens/Product'))
const Success = React.lazy(() => import('../screens/Success'))
const Error = React.lazy(() => import('../screens/Error'))
const AllResults = React.lazy(() => import('../screens/AllResults'))
const SearchLoading = React.lazy(() => import('../components/SearchLoading'))

const Offers = React.lazy(() => import('../screens/Offers'))
const Opportunity = React.lazy(() => import('../screens/Opportunity'))
const OpportunityDetails = React.lazy(() => import('../screens/OpportunityDetails'))
const ThankYou = React.lazy(() => import('../screens/ThankYou'))
const OffersFinish = React.lazy(() => import('../screens/OffersFinish'))

const Login = React.lazy(() => import('../screens/Auth/Login'))
const CadChat = React.lazy(() => import('../screens/Auth/CadChat'))

const Chat = React.lazy(() => import('../components/Chat'))


const AppRoutes = () => {

    const {
        signed,
    } = useContext(AppContext);

    return(
        <BrowserRouter>
            <React.Suspense fallback={<div>Loading...</div>}>
                {
                    !signed ? (
                        <Routes>
                            <Route path='/' element={<Home />} />
                            <Route path='/error' element={<Error />} />
                            <Route path='/list/:Id' element={<ResultList />} />            
                            <Route path='/success' element={<Success />} />
                            <Route path='/:Id/product/:Offer' element={<Product />} />
                            <Route path='/results' element={<AllResults />} />
                            <Route path='/loading' element={<SearchLoading />} />
                            <Route path='/feirante/thankyou' element={<ThankYou />} />
                            <Route path='/feirante/chat/:Id/:Banca' element={<Chat />} />
                            <Route path='/userChat/:Id/:Banca' element={<Chat />} />
                            
                            <Route path='/feirante/login' element={<AuthRoute><Login /></AuthRoute>} />
                            <Route path='/feirante/cadastro' element={<CadChat />} />

                            <Route path='/feirante/opportunity/:Id' element={<Navigate to="/feirante/login" />} />
                            <Route path='/feirante' element={<Navigate to="/feirante/login" />} />
                            <Route path='/feirante/oppdetails/:Id' element={<Navigate to="/feirante/login" />} />
                            <Route path='/feirante/finished' element={<Navigate to="/feirante/login" />} />
                        </Routes>
                    ) : (
                        <Routes>
                            <Route path='/' element={<Home />} />
                            <Route path='/error' element={<Error />} />
                            <Route path='/list/:Id' element={<ResultList />} />            
                            <Route path='/success' element={<Success />} />
                            <Route path='/:Id/product/:Offer' element={<Product />} />
                            <Route path='/results' element={<AllResults />} />
                            <Route path='/loading' element={<SearchLoading />} />
                            <Route path='/feirante/thankyou' element={<ThankYou />} />
                            <Route path='/feirante/chat/:Id/:Banca' element={<Chat />} />
                            <Route path='/userChat/:Id/:Banca' element={<Chat />} />

                            <Route path='/feirante/login' element={<Navigate to="/feirante" />} />
                            <Route path='/feirante/cadastro' element={<Navigate to="/feirante" />} />

                            <Route path='/feirante/opportunity/:Id' element={<Private isprivate={true}> <Opportunity /> </Private>} />
                            <Route path='/feirante' element={<Private isprivate={true}> <Offers /> </Private>} />
                            <Route path='/feirante/finished' element={<Private isprivate={true}> <OffersFinish /> </Private>} />
                            <Route path='/feirante/oppdetails/:Id' element={<Private isprivate={true}> <OpportunityDetails /> </Private>} />
                        </Routes>
                    ) 
                }
            </React.Suspense>
        </BrowserRouter>
    )
}

export default AppRoutes;