const { LOCAL_API } = require("../../../constants/serviceConstants");

export const SEARCH_API = LOCAL_API + "v1/search/";

export const SEARCH_API_V2 = LOCAL_API + "v2/search/";

export const SEARCH_API_V1 = LOCAL_API + "v1/search/";

export const NEGOCIATION_API = LOCAL_API + "v1/negociation/";

export const OFFER_API = LOCAL_API + "v1/offer/";

export const OFFER_UNITY_API = LOCAL_API + "v1/offerUnity/";

export const CHAT_API = LOCAL_API + "v1/chat/";

export const CAD_CHAT_API = LOCAL_API + "v1/localeCreate/";

export const ML_API = "https://api.mercadolibre.com/sites/MLB/search?q=";

export const ML_CATEGORY_API = "https://api.mercadolibre.com/catalog_domains/";

export const PRODUCT_API = LOCAL_API + "v1/createProduct";