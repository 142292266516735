import { 
    CAD_CHAT_API,
    CHAT_API,
    ML_API, 
    ML_CATEGORY_API, 
    NEGOCIATION_API, 
    OFFER_API, 
    OFFER_UNITY_API, 
    PRODUCT_API, 
    SEARCH_API, 
    SEARCH_API_V1, 
    SEARCH_API_V2 } from "../constants"

export const getSearch = async (value) => {
    const url = SEARCH_API + value;

    try{
        const settings = { 
            method: 'GET',     
            mode: 'cors',    
            headers: new Headers({       'Content-Type': 'application/json'     })
        }
        const fetchResponse = await fetch(url, settings);
        const data = await fetchResponse.json()
        return data;
    } catch(error) {
        return error;
    }
}

export const getLocaleList = async (value) => {
    const url = SEARCH_API_V1 + value;
    try {
        const settings = { 
            method: 'GET',     
            mode: 'cors',    
            headers: new Headers({       'Content-Type': 'application/json'     })
        }

        const fetchResponse2 = await fetch(url, settings);
        let data2 = await fetchResponse2.text();
        return data2;
    }
    catch(error) {
        return undefined;
    }
}

export const getSearchV2 = async (value) => {
    const url = ML_API + value;
    const url3 = PRODUCT_API;
    // const categoryValues = [];
    // const responseValues = [];
    // const urls = [];
    try{
        const fetchResponse = await fetch(url);
        const data = await fetchResponse.json();
        const valueresults = data.results;
        const finalName = valueresults[0].domain_id.split("MLB-")
        const url2 = SEARCH_API_V2 + finalName[1];
        const categoryURL = ML_CATEGORY_API + valueresults[0].domain_id;
        const fetchResponse3 = await fetch(categoryURL);
        const data3 = await fetchResponse3.json();
        const categoryName = data3.name;

        const settings = { 
            method: 'POST',     
            mode: 'cors',    
            headers: new Headers({       'Content-Type': 'application/json'     }),
            body: JSON.stringify({
                name: value,
              })
        }

        const settings2 = { 
            method: 'POST',     
            mode: 'cors',    
            headers: new Headers({       'Content-Type': 'application/json'     }),
            body: JSON.stringify({
                name: value,
                category: finalName[1],
                categoryName: categoryName,
              })
        }

        await fetch(url3, settings2);
        const fetchResponse2 = await fetch(url2, settings);
        let data2 = await fetchResponse2.text();
        return data2;

    }
    catch(error) {
        return undefined;
    }
}

export const getNegociation = async (value) => {
    const url = NEGOCIATION_API + value;
    const url2 = OFFER_API + value;
    try{
        const settings = { 
            method: 'GET',     
            mode: 'cors',     
            headers: new Headers({       'Content-Type': 'application/json'     })
        }
        const fetchResponse = await fetch(url, settings);
        const data = await fetchResponse.json()

        const fetchResponse2 = await fetch(url2, settings);
        const data2 = await fetchResponse2.json()

        const finalData = {
            offer: data2,
            negociation: data
        }
        return finalData
    } catch(error) {
        return error;
    }
}


export const getOffer = async (value,locale) => {
    const url = OFFER_UNITY_API + value + "/" + locale;
    try{
        const settings = { 
            method: 'GET',     
            mode: 'cors',     
            headers: new Headers({       'Content-Type': 'application/json'     })
        }
        const fetchResponse = await fetch(url, settings);
        const data = await fetchResponse.json()

        return data;
    } catch(error) {
        return error;
    }
}

export const getChat = async (value,locale) => {
    const url = CHAT_API + value + "/" + locale;
    try{
        const settings = { 
            method: 'GET',     
            mode: 'cors',     
            headers: new Headers({       'Content-Type': 'application/json'     })
        }
        const fetchResponse = await fetch(url, settings);
        const data = await fetchResponse.json()

        return data;
    } catch(error) {
        return error;
    }
}

export const postChat = async (id,banca,message,enviado) => {
    const url = CHAT_API + id + "/" + banca;
    try{
        const settings = { 
            method: 'POST',     
            mode: 'cors',     
            headers: new Headers({       'Content-Type': 'application/json'     }),
            body: JSON.stringify({
                enviado: enviado,
                mensagem: message
            })
        }
        const fetchResponse = await fetch(url, settings);
        const data = await fetchResponse.json()

        return data;
    } catch(error) {
        return error;
    }
}

export const postChatCad = async (value,id) => {
    const url = CAD_CHAT_API + id ;
    try{
        const settings = { 
            method: 'POST',     
            mode: 'cors',     
            headers: new Headers({       'Content-Type': 'application/json'     }),
            body: JSON.stringify(value)
        }
        const fetchResponse = await fetch(url, settings);
        const data = await fetchResponse.json()
        return data;

    } catch(error) {
        return error;
    }
}