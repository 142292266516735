import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBHYXM8Nu2-NtA-H9D7oMCSZPl7m_i0DAM",
  authDomain: "feirafacil-2022.firebaseapp.com",
  projectId: "feirafacil-2022",
  storageBucket: "feirafacil-2022.appspot.com",
  messagingSenderId: "579491759728",
  appId: "1:579491759728:web:86443207dd8829526696b5",
  measurementId: "G-1LW0047692"
};

const app = initializeApp(firebaseConfig);
// const database = getFirestore(app)

export default app;