import React, { createContext, useContext, useEffect } from 'react';
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import FeiranteHooks from './hooks';
import { AppContext } from '../AppContext';

export const FeiranteContext = createContext({});

export default function FeiranteProvider({children}){
    const {
      localeData,
      offersList,
      newOffer,
      product,
      oldOffer,
      banca,
      offerListActives,
      localeGet,
      createOffer,
      setOldOffer,
      OfferListGet,
      offerfinish,
      offerDecline,
      offerRefuse,
      offerListActivesOld,
      setOfferListActivesOld,
      setBanca,
      categories,
      categoriesState,
      loading,
      setUser,
      setLoading,
      user,
      setNewOffer,
      offersFinished,
      loadStorage,
      finished,
      bancaData
    } = FeiranteHooks({ collection, getDoc, getDocs, doc , query, where })

    return (
        <FeiranteContext.Provider
          value={{
            localeData,
            offersList,
            bancaData,
            newOffer,
            product,
            oldOffer,
            banca,
            offerListActives,
            localeGet,
            createOffer,
            setOldOffer,
            OfferListGet,
            offerfinish,
            offerDecline,
            offerRefuse,
            loadStorage,
            offerListActivesOld,
            setOfferListActivesOld,
            setBanca,
            categories,
            categoriesState,
            loading,
            setUser,
            setLoading,
            user,
            setNewOffer,
            offersFinished,
            finished
        }}>
          {children}
        </FeiranteContext.Provider>
      );
}
