import React from 'react';
import Routes from './routes';
import './global.css';
import AppProvider from './contexts/AppContext';
import FeiranteProvider from './contexts/FeiranteContext';

function App() {
  return (
    <div className="App">
      <AppProvider>
        <FeiranteProvider>
          <Routes />
        </FeiranteProvider>
      </AppProvider>
    </div>
  );
}

export default App;
