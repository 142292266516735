import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useContext, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom"
import { AppContext } from "../contexts/AppContext";
import { FeiranteContext } from "../contexts/FeiranteContext";

const Private = ({ children, isprivate }) => {
    const location = useLocation()
    const {
            newOffer,
            oldOffer,
            localeGet,
            setOldOffer,
            setBanca,
            banca,
            offersFinished,
            loading,
            user,
            setLoading,
            setUser,
            loadStorage
      } = useContext(FeiranteContext);

    const {
        signed, 
        setSigned,
    } = useContext(AppContext);
  
    useEffect(() => {
        loadStorage();
    },[])

    if(loading){
        return(
            <div/>
        )
    }
        if((newOffer[0] !== oldOffer[0] && newOffer !== []) && (location.pathname !== `/feirante/opportunity/${newOffer[0]}` && (newOffer[0]) !== undefined)){
            // setOldOffer([newOffer[0], ...oldOffer])
            return <Navigate to={`/feirante/opportunity/${newOffer[0]}`} replace={false}/> 
        } else {
            return children
        }
}

export default Private;