import { useState } from 'react';
import * as service from '../services';
import { getNegociation } from '../../AppContext/services';
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import app from '../../../service/firebaseConnection';
import { async } from '@firebase/util';

export default function FeiranteHooks({ collection, getDoc, doc, getDocs, query, where }){
    const functions = getFunctions(app);
    connectFunctionsEmulator(functions, "localhost", 5002);
    
    const [product] = useState("");
    const [banca, setBanca] = useState("");
    const [localeData, setLocaleData] = useState("");
    const [offersList, setOffersList] = useState([]);
    const [offersFinished, setOffersFinished] =useState([])
    const [newOffer, setNewOffer] = useState([]);
    const [oldOffer, setOldOffer] = useState([]);
    const [offerListActives, setOfferListActives] = useState([]);
    const [offerListActivesOld, setOfferListActivesOld] = useState([]);
    const [categoriesState, setCategoriesState] = useState([]);
    const [loading, setLoading] = useState(true);
    const [bancaData, setBancaData] = useState({});
    const [user, setUser] = useState(null)

    const loadStorage = async () => {
            setLoading(true);
            const storageUser = localStorage.getItem("@detailUser")
            if(storageUser !== ""){
                const data = JSON.parse(storageUser)
                if(data && data !== null){
                    setUser(data);
                    setBanca(data.uid);
                    const bancadata = await service.getBanca(data.uid)
                    setBancaData(bancadata)
                    setLoading(false)
                }  
            }
            
            setLoading(false)
    }


    const createOffer = async (value,id,valor,desconto) => {
         await service.postOffer(value,id,valor,desconto)
    }

    const categories = async () => {
       const result = await service.getCategories();
       setCategoriesState(result);
   }

   const finished = async (response) => {
       let aux = []
        if(response.negociacoes_finalizadas.length > 0){
            response.negociacoes_finalizadas.forEach(async element => {
                const responseNegociation = await getNegociation(element);
                aux = [responseNegociation, ...aux]
                const aux2 = [...new Set(aux.map(JSON.stringify))].map(JSON.parse)
                setOffersFinished(aux2)
            })
        }
   }


    const localeGet = async (banca) => {
        const response = await service.getBanca(banca)
        setLocaleData(response);
        if(response.negociacoes_ativas.length > 0){
            setOffersList(response.negociacoes_ativas);
        }
        if(response.negociacoes_passivas.length > 0 && response.negociacoes_passivas.length !== newOffer.length){
            let arr = [];
            if(response.negociacoes_passivas.length > 1) {
                response.negociacoes_passivas.map(async element  => {
                    const responseNegociation = await getNegociation(element);
                    const aux = new Date(responseNegociation.negociation.created_at._seconds * 1000)
                    const aux2 = new Date(Date.now())
                    if((aux2 - aux) >= 1200000){ 
                    offerDecline(element)
                    }
                    else{
                        arr = [element, ...arr]
                        const arrAux = [...new Set(arr.map(JSON.stringify))].map(JSON.parse);
                        setNewOffer(arrAux)
                    }
                })
            } else {
                setNewOffer(response.negociacoes_passivas)
            }
        }
        await finished(response)
    }

    const offerfinish = async (id) => {
        await service.postOfferFinish(id,banca);
    }

    const offerDecline = async (id) => {
        const arr = [];
        await service.postOfferDecline(id,banca);
        newOffer.map(element => {
            if(element !== id){
                arr.push(element)
            }
        })
        const arrAux = [...new Set(arr.map(JSON.stringify))].map(JSON.parse);
        setNewOffer(arrAux)
    }

    const offerRefuse = async (id) => {
        await service.postOfferRefuse(id,banca);
    }

    const OfferListGet = async () => {
        let arr = [];
        const response = await service.getOfferList(banca);
        response.map(element => {
            const aux = new Date(element.offer.created_at._seconds * 1000)
            const aux2 = new Date(Date.now())
            if((aux2 - aux) >= 1200000){
              const id = element.offer.id;
              offerRefuse(id)
            }
            else arr = [element, ...arr]
          })
          setOfferListActives(arr);
    }

    return (
        {
            localeData,
            offersList,
            newOffer,
            product,
            oldOffer,
            banca,
            offerListActives,
            localeGet,
            createOffer,
            setOldOffer,
            OfferListGet,
            offerfinish,
            offerDecline,
            offerRefuse,
            offerListActivesOld,
            setOfferListActivesOld,
            setBanca,
            categories,
            categoriesState,
            loading,
            user,
            setUser,
            setLoading,
            setNewOffer,
            offersFinished,
            finished,
            loadStorage,
            bancaData
        }
      );
}