import React, {useState, useEffect} from 'react';
import { getAuth } from 'firebase/auth';
import { Outlet, Navigate } from 'react-router-dom';

export const AuthRoute = ({children}) => {
    // const auth = getAuth();
    const [tempState, setTempState] = useState(null)
    useEffect(() => {
        const storageUser = localStorage.getItem("@detailUser");
        if(storageUser !== ""){
            const data = JSON.parse(storageUser)
            if(data && data !== null){
                setTempState()
            }
        }
    },[]);
    
    return tempState ? <Navigate to="/feirante" replace /> : children;
};