import React, { createContext, useEffect } from 'react';
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import AppHooks from './hooks';

export const AppContext = createContext({});

export default function AppProvider({children}){
    const {
      offerList,
      product,
      localeList,
      offerState,
      offer,
      search,
      setLocaleList,
      offerUnity,
      chatSend,
      chatGet,
      chatList,
      auth,
      signOn,
      signIn,
      cadChatSend,
      signed,
      AllResults,
      logout,
      setOfferList,
      offerfinish, 
      setSigned } = AppHooks({ collection, getDoc, getDocs, doc , query, where })
      
    return (
        <AppContext.Provider
          value={{
            offerList,
            product,
            localeList,
            offerState,
            offer,
            search,
            setLocaleList,
            offerUnity,
            chatSend,
            chatGet,
            chatList,
            auth,
            signOn,
            signIn,
            cadChatSend,
            signed, 
            setSigned,
            AllResults,
            logout,
            offerfinish,
            setOfferList
          }}>
          {children}
        </AppContext.Provider>
      );
}
